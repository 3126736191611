import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "./style.css";

import grubProblemInLawn from "../../../assets/img/grub-problem-in-lawn.png";
import grubsInHand from "../../../assets/img/grubs-in-hand.jpg";
import lawnDamageByGrubs from "../../../assets/img/lawn-damaged-by-grubs.png";
import organicFertilizerInHand from "../../../assets/img/organic-fertilizer-in-hand.png";
import grubLifeCycle from "../../../assets/img/chafer-beetle-lifecycle-vancouver.png";
import springFlowerBloom from "../../../assets/img/spring-flower-bloom.jpeg";
import summerLandscapeView from "../../../assets/img/summer-landscape-view.jpeg";
import fallLandscapePath from "../../../assets/img/fall-landscape-path.jpeg";

export default function ChaferBeetleControl() {
  
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle ="European Chafer Beetle Control, Treatment & Lawn Repair | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
      "Protect your lawn from European Chafer Beetle damage. Learn to identify, prevent, and repair grub infestations. Serving Vancouver, North Vancouver, West Vancouver and surrounding areas.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "European chafer beetle control, European chafer beetle, European Chafer, vancouver chafer beetle, chafer beetles, chafer beetle, chafer, beetle, schafer beetle, grub, bug, lawn pest, lawn care, damage control, north vancouver lawn damage , north shore chafer damage, west vancouver lawn damage, grubs, chafer beetles, chafer damage, lawn damage, merit, acelepryn, nematodes, crow damage, animal damage",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">European Chafer Beetle Control</h1>
      </div>
      <div className="serviceItemsMain">
        <h2
          data-aos="fade-down"
          style={{
            fontSize: "3vw",
            color: "var(--green-color)",
            letterSpacing: ".2rem",
          }}
        >
        European Chafer Beetle Control & Treatment Services     </h2>
        <hr style={{ marginBottom: "2vw" }} />
        <div className="container-grubgone">
          <div className="row">
            {/* -------------- about SECTION -------------*/}
            <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img
                  src={grubsInHand}
                  alt="Lawn damaged by chafer beetle infestation in Vancouver"
                  title="Grubs Removed from Lawn"
                />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  What is The European Chafer Beetle?
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                The European Chafer Beetle originated in continental Europe but can now be found in temperate climates across North America. Our specialized <strong>chafer beetle treatment</strong> and <strong>chafer beetle control</strong> services protect your lawn from grub damage.
                Learn more about <a href="/blog/chafer-beetle" className="internal-link"> the Chafer Beetle Lifecycle</a>. The lifecycle of the Chafer Beetle is one year, with
                  new eggs hatching in July pending weather conditions. These
                  voracious pests feed on grass roots, causing significant
                  damage to lawns. Their feeding activity creates brown patches,
                  wilting grass, and eventually bare spots in the lawn.
                  Additionally, the presence of grubs attracts predators like
                  crows, skunks and racoons, which further disrupt the turf
                  while foraging for food. If left untreated, grub infestations
                  can devastate lawns, leading to costly repairs and diminished
                  curb appeal.
                </p>
              </div>
            </section>
            <section className="hero" data-aos="fade-up"></section>
            <section className="section-container">
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  Tips for identifying a grub infestation on lawns:
                </h3>
                <ul>
                  <li>
                    <strong>Inspect the Soil:</strong> Gently lift a section of
                    the turf and examine the soil underneath. Grubs typically
                    reside within the top few inches of soil, so look for small,
                    C-shaped larvae.
                  </li>
                  <li>
                    <strong>Brown Patches:</strong> Keep an eye out for
                    irregular patches of brown or dead grass in your lawn. Grub
                    feeding disrupts the root system, leading to areas of
                    weakened or dying grass. Grubs will continue to work their
                    way outward from the center of damage and can persist up to
                    one meter away from any obviously damaged edges.
                  </li>
                  <li>
                    <strong>Animal Activity: </strong> Wildlife such as skunks,
                    raccoons, and birds are attracted to lawns infested with
                    grubs. Look for signs of digging or foraging, as these
                    animals may be seeking out grubs as a food source.
                  </li>
                  <li>
                    <strong>Lawn Damage:</strong> Grub feeding can cause the
                    turf to detach from the soil, making it easy to roll up like
                    a carpet. Check for areas where the grass lifts easily or
                    where it appears to have been pulled back.
                  </li>
                  <li>
                    <strong>Check During Active Periods: </strong> Grubs are
                    most active in late summer and early fall when they are
                    feeding and growing. Be especially vigilant for signs of
                    infestation during these times.
                  </li>
                </ul>
              </div>
              <div className="image-container px-5" data-aos="fade-left">
                <img
                  src={grubProblemInLawn}
                  alt="A close-up view of a lawn damaged by grubs, showcasing the soil and grass condition."
                  title="Lawn Damaged by Grubs"
                />
              </div>
            </section>
            {/* -------------- END OF ABOUT SECTION -------------*/}
            <section className="section-container gb">
              <div className="image-container px-5" data-aos="fade-right">
                <img
                  src={lawnDamageByGrubs}
                  alt="A lawn visibly affected by grub infestation, with damaged grass and exposed soil."
                  title="Grubs causing lawn damage due to European chafer beetle infestation"
                />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Chafer Beetle Control & Treatment - Repairing Lawn Damage During Growing Seasons:
                </h3>
                <ul>
                  <li>
                    <strong>Rake Away Old Debris:</strong> This will expose any
                    existing grubs and make it easier for wildlife to forage for
                    them.
                  </li>
                  <li>
                    <strong>Assess Damage:</strong> Come mid-late March (or
                    whenever temperatures begin to warm up) assess the extent of
                    the damage to your lawn and consider either repairing with
                    soil/seed or for larger areas or more extensive damage - new
                    sod.
                  </li>
                  <li>
                    <strong>
                      Keep Up with Healthy Lawn Care Maintenance Practices:
                    </strong>{" "}
                    The best form of defense against the Chafer Beetle is to
                    keep up with regular, healthy lawn care practices such as
                    regular lawn cutting, watering, consistent fertilization,
                    etc. The stronger and healthier you can get the root system
                    of your lawn, the less vulnerable it will be to future
                    Chafer Beetle Attacks.
                  </li>
                  <li>
                    <strong>Do Grub Gone Applications Each Season:</strong>   As the lifecycle of the Chafer Beetle is only one year, it's important to apply Grub Gone to protect your lawn.  
                    <a href="/contact-us" className="internal-link"> Get a quote for chafer beetle control and lawn repair services today!</a>


                  </li>
                </ul>
              </div>
            </section>
            <section className="section-container bgrubcycle">
              <div className="text-container px-5 grubText " data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  The European Chafer Beetle lifecycle:
                </h3>
                <p>
                  The European Chafer Beetle has a lifecycle of one year. Eggs
                  hatch in summer and grubs begin to feed on turfgrass roots.
                  Come winter, they pupate underground, emerging as beetles next
                  spring to begin the cycle anew. With mild climates like those
                  in North & West Vancouver, chafer beetle grubs can feed on
                  your turfgrass from mid-August through to April, causing
                  damage to your lawn.
                </p>
              </div>
              <div className="image-container grubImg" data-aos="fade-right">
                <img
                  src={grubLifeCycle}
                  alt="Illustration showing the lifecycle of grubs from larva to beetle, helping understand pest development."
                  title="Lifecycle of Grubs"
                />
              </div>
            </section>
            <section
              id="timesOfTheYearSection"
              className="timesOfTheYearSection"
            >
              <div className="section-container" data-aos="fade-up">
                <div className="section-header">
                  <h2 data-aos="fade-down">
                    Times Of Year You Can Apply Grub Gone!:
                  </h2>
                </div>
                <div className="row mb-5 ">
                  <div
                    className="col-33 circlexyear"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img
                          src={springFlowerBloom}
                          className="img-fluid"
                          alt="A close-up view of spring flowers blooming, symbolizing the beauty of seasonal gardening."
                          title="Spring Flower Bloom"
                        />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Spring</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-33 circlexyear"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img
                          src={summerLandscapeView}
                          className="img-fluid"
                          alt="A serene summer landscape with a beautiful sunset over a lake, creating a tranquil atmosphere."
                          title="Summer Landscape View"
                        />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Summer</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-33 circlexyear "
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img
                          src={fallLandscapePath}
                          className="img-fluid"
                          alt="A scenic path covered with fall leaves, showcasing the beauty of the autumn season."
                          title="Fall Landscape Path"
                        />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Fall</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" text-center">
                    <div className="post-description">
                      <p className="post-title" data-aos="fade-up">
                        {" "}
                        <strong>SPRING: </strong> You have evidence of some
                        activity in your lawn or you see it in your immediate
                        neighbours. The colony may not be large enough in your
                        lawn yet to show damage. Also encourage your neighbour
                        to apply at this time to limit the spread from their
                        lawns.
                      </p>
                      <p className="post-title" data-aos="fade-up">
                        {" "}
                        <strong>SUMMER:</strong> You are doubling down on your
                        spring action or you did not do it in the spring and
                        your neighbours have grub activity. Generally speaking
                        new colony's won't have a large effect on the lawn at
                        this time but are very vulnerable to grub gone in their
                        earliest instars.{" "}
                      </p>
                      <p className="post-title" data-aos="fade-up">
                        <strong>FALL:</strong> You are doing your second
                        application due to known activity and to arrest any
                        activity seen in the fall in your lawn. Applying at this
                        time will limit the colony size over the greatest length
                        of time that it will be feeding in your lawn. A colony
                        left unchecked at this time of year can damage a whole
                        lawn rather than a portion of the lawn. It is often the
                        first application for people that have never had chafer
                        damage before, and is the most significant for those
                        that this is true for. Reducing the colony size now will
                        limit any damage.{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="row cl px-5">
              <div
                className="col-xl-5 col-lg-5 col-md-12 col-sm-12 xs-12 grubProduct "
                data-aos="fade-right"
              >
                <img
                  src={organicFertilizerInHand}
                  className="img-shadow fl grubProductImg"
                  alt="Hands holding organic fertilizer, ideal for natural lawn care and soil enrichment."
                  title="Organic Fertilizer for Lawn Care"
                />
              </div>
              <div
                className="col-xl-7 col-lg-7 col-md-11 col-sm-11 xs-10 mb-4 grubProductText  "
                data-aos="fade-left"
              >
                <h3 style={{ textTransform: "uppercase", paddingTop: "1.5vw" }}>
                  Product information
                </h3>
                <p
                  className="heading-colored-text"
                  style={{ letterSpacing: "0" }}
                >
                  Grub gone is the first Bacillus thuringiensis galleriae (Btg)
                  soil bacterium that effectively controls turf grubs at a level
                  equivalent to chemical standards [banned by pesticide bylaws]
                  without the nasty side effects or off-target damage to
                  beneficial insects (such as bees and other pollinators).
                  Unlike Nematodes, Grub Gone is effective against 1st, 2nd &
                  3rd instar pests and therefore can be used as a curative
                  treatment as well as a preventative one. Being an organic
                  product, it will not have a 100% guarantee in completely
                  killing all the grubs in your lawn but the effectiveness rate
                  when combined with healthy lawn care maintenance is closer to
                  90% (while Nematodes was only 60%). The product itself is
                  light brown in colour, finely ground, and only requires one
                  deep watering within a 3 day window of being applied (in order
                  to move the product deeper into the level of the soil where
                  grubs can feed). Once applied to your lawn and watered in,
                  Grub Gone will remain in the soil for up to 3 months.
                </p>
              </div>
            </div>
            {/* -------------- END OF BANNER -------------*/}
            <div className="secondBanner">
              <div className="container" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-8">
                    <h3 className="grubBannerTitle">
                      #1 Recommendation to avoid future chafer beetle damage:
                    </h3>
                    <p className="grubBannerText">
                      Keep up with regular healthy lawn care and maintenance,
                      regular lawn cutting, deep core aeration, fertilization,
                      watering, etc. & DO A GRUB GONE APPLICATION EACH SEASON.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-4">
                    <div className="call-to-action text-right">
                      <a
                        href="/contact-us"
                        className="get-a-quote grubBannerButton"
                      >
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
