import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import beforeAfter from "../../../assets/img/before-after-paver-patio.jpg";
import excavationPathwayRemoval from "../../../assets/img/excavation-pathway-removal.jpg";
import pathwayClearingExcavation from "../../../assets/img/pathway-clearing-excavation.jpg";
import deepSoilExcavation from "../../../assets/img/deep-soil-excavation-pathway.jpg";
import completedPathwayExcavation from "../../../assets/img/completed-pathway-excavation.jpg";
import deepPathwayExcavation from "../../../assets/img/deep-pathway-excavation-complete.jpg.jpg";
import cleanExcavationForPathway from "../../../assets/img/clean-excavation-for-pathway.jpg";
import gravelBasePathway from "../../../assets/img/gravel-base-pathway-installation.jpg";
import compactedGravelPathway from "../../../assets/img/compacted-gravel-pathway-base.jpg";
import smoothGravelFinish from "../../../assets/img/smooth-gravel-finish-pathway.jpg";
import paverInstallationStart from "../../../assets/img/paver-installation-start.jpg";
import halfwayPaverPathway from "../../../assets/img/halfway-paver-pathway-installation.jpg";
import patternPaverPathway from "../../../assets/img/patterned-paver-pathway.jpg";
import paverEdgeAlignment from "../../../assets/img/paver-edge-alignment.jpg";
import finishedPaverPathway from "../../../assets/img/finished-paver-pathway-installation.jpg";
import paverPathwayComplete from "../../../assets/img/paver-pathway-complete-sanding.jpg";
import finishedPaverWalkway from "../../../assets/img/finished-paver-walkway.jpg";
import paverCornerDetail from "../../../assets/img/paver-corner-detail-installation.jpg";
import seamlessPaverTransition from "../../../assets/img/seamless-paver-transition.jpg";
import paverPathwayFinalTouch from "../../../assets/img/paver-pathway-final-touch.jpg";
import completedModernPaverPathway from "../../../assets/img/ completed-modern-paver-pathway.jpg";
import elegantPaverWalkwayInstallation from "../../../assets/img/elegant-paver-walkway-installation.jpg";
import finishedHerringBonePaver from "../../../assets/img/ finished-herringbone-paver-pathway.jpg";

export default function ExpertWalkwayBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Walkway Installation Guide for Greater Vancouver";
    document.title = newTitle;

    const newDescription =
      "Learn how to install a durable and beautiful walkway with expert guidance tailored for Greater Vancouver's climate. Explore tips for proper drainage, material selection, and base preparation to ensure long-lasting results.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Affordable and durable walkway installations in Burnaby and Richmond, eco-friendly and permeable paver walkways for rainy climates in North Vancouver, custom flagstone and brick pathways for luxury homes in West Vancouver, top-rated walkway contractors near me in Surrey and White Rock, best materials and designs for walkways in Greater Vancouver, how much does a paver walkway cost in Vancouver.",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost expertWalkway">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Expert Walkway Installation
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                A Complete Guide for Greater Vancouver
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              In the Greater Vancouver area-spanning Vancouver, Burnaby,
              Richmond, North Vancouver, West Vancouver, Surrey, Coquitlam, Port
              Coquitlam, Port Moody, Delta, Langley (City and Township), Maple
              Ridge, White Rock, New Westminster, and Pitt Meadows—heavy rains
              are common, and proper drainage is essential. Installing a durable
              and visually appealing walkway requires careful planning and
              preparation. Using an open-graded base with three-quarter inch
              clear crush and nine-mil bedding ensures exceptional drainage and
              stability, while choosing whether or not to use jointing sand will
              impact moisture flow and stability. Here's a guide tailored to our
              unique climate and region.
            </p>
            <img
              src={beforeAfter}
              alt="High-quality paver walkway in Vancouver backyard."
              title="Walkway Installation Services by BUR-HAN Garden & Lawn Care"
              className="img-responsive"
            />
            <p>
              <span>1. Design and Planning:</span>
              <br />
              <span>- Plan for Drainage:</span> In Greater Vancouver's rainy
              climate, ensure the walkway is sloped (1-2%) away from buildings
              and structures to prevent water pooling. <br />
              <img
                src={excavationPathwayRemoval}
                alt="Initial stage of pathway excavation with broken asphalt pieces on the ground"
                title="Pathway Excavation and Asphalt Removal in Progress"
                className="img-responsive"
              />
              <br />
              <span>-Material Selection:</span> Choose Belgard pavers that are
              durable and complement your outdoor space. Materials should be
              suited to high-moisture environments to prevent erosion and
              discoloration.
            </p>
            <img
              src={pathwayClearingExcavation}
              alt="Partially excavated pathway with asphalt debris and exposed soil."
              title="Clearing and Excavating Pathway for New Installation"
              className="img-responsive"
            />
            <p>
              <span>2. Site Assessment:</span>
              <br />
              <span>-Utility Check:</span> Identify and mark any underground
              utilities before beginning excavation to ensure safety and avoid
              disruptions.
              <br />
              <img
                src={deepSoilExcavation}
                alt="Deeper excavation of a garden pathway with removed surface layers."
                title="Advanced Excavation of Pathway for Landscaping Project"
                className="img-responsive"
              />
              <br />
              <span> -Soil Evaluation:</span> Analyze the site's soil conditions
              to determine the required base depth and confirm it can support
              the walkway structure.
            </p>
            <img
              src={completedPathwayExcavation}
              alt="Fully excavated garden pathway ready for new construction."
              title="Final Stage of Pathway Excavation for Landscaping"
              className="img-responsive"
            />
            <p>
              <span>3. Excavation:</span>
              <br />
              Proper excavation ensures a solid and long-lasting walkway.
              <br />
              - Define Boundaries:
              <br />
              - Mark the walkway's layout using stakes and string lines.
              <br />
              - Remove Organic Soils:
              <br />
              - Excavate all organic material, including topsoil, grass, and
              roots, to prevent settlement and instability.
              <br />
              - Reach Native Soil:
              <br />
              - Dig down to expose stable native soil, which provides a firm
              foundation.
              <br />
              - Depth Requirements:
              <br />
              - Ensure sufficient depth for:
              <br />
              - 4-6 inches for the open-graded base.
              <br />
              - 1 inch for nine-mil bedding.
              <br />
              - The paver thickness.
              <br />
              - Ensure a Slope:
              <br />- Maintain a slight slope (1-2%) to promote proper drainage
              and prevent water buildup.
            </p>
            <img
              src={deepPathwayExcavation}
              alt="Completed deep excavation of a garden pathway with tools visible on the side."
              title="Fully Excavated Pathway Ready for Base Installation"
              className="img-responsive"
            />
            <p>
              <span>4. Base Preparation:</span>
              <br />
              - Adjust Base Thickness: If additional excavation is necessary to
              remove organic materials or reach stable native soil, increase the
              thickness of the base material accordingly. This ensures the
              foundation remains strong and durable, regardless of the depth.
              <br />
              - Spread the Base Material:
              <br />
              - Lay a minimum of 4-6 inches of three-quarter inch clear crush
              for standard installations.
              <br />
              - For deeper excavations:
              <br />
              - Add enough clear crush to compensate for the extra depth,
              compacting in 2-inch layers until the base is level with the
              design grade.
              <br />
              <img
                src={cleanExcavationForPathway}
                alt="Cleared and leveled pathway excavation site prepared for gravel base."
                title="Clean and Leveled Pathway Excavation"
                className="img-responsive"
              />
              <br />
              - Compact the Base:
              <br />
              - Use a plate compactor to compact the base in 2-inch layers. Each
              layer must be firmly compacted before adding the next to prevent
              settling over time.
              <br />
              - Maintain Proper Grading:
              <br />
              - Ensure the compacted base maintains a 1-2% slope for proper
              drainage, directing water away from structures.
              <br />
              By modifying the base thickness to match the excavation depth, you
              create a stable and resilient foundation tailored to the specific
              needs of the site, ensuring long-term durability.
            </p>
            <img
              src={gravelBasePathway}
              alt=" Initial gravel base being installed along the excavated garden pathway."
              title="Starting Gravel Base Installation for Pathway"
              className="img-responsive"
            />
            <p>
              <span>5. Paver Installation:</span>
              <br />
              - Install Edge Restraints:
              <br />
              - Place edge restraints along the walkway's perimeter to prevent
              pavers from shifting or settling over time.
              <br />
              <img
                src={compactedGravelPathway}
                alt="Compacted gravel base layer fully covering the excavated pathway."
                title="Compacted Gravel Base Completed for Pathway Foundation"
                className="img-responsive"
              />
              <br />
              - Anchor the Restraints:
              <br />
              - Secure them with spikes, ensuring they sit flush with the
              compacted base.
              <br />
            </p>
            <img
              src={smoothGravelFinish}
              alt=" Smooth and even gravel layer finishing the pathway base preparation."
              title="Final Smooth Gravel Finish for Pathway Installation"
              className="img-responsive"
            />
            <p>
              <span>6. Optional - Jointing Sand:</span>
              <br />
              The nine-mil bedding material ensures proper drainage and even
              placement of the pavers.
              <br />
              <img
                src={paverInstallationStart}
                alt="Beginning of paver installation on a gravel base with tools and materials in view."
                title="Starting Paver Installation on a Prepared Gravel Base"
                className="img-responsive"
              />
              <br />
              - Spread the Bedding Material:
              <br />
              - Apply a 1-inch layer of nine-mil clear crush over the compacted
              base.
              <br />
              - Level the Bedding Layer:
              <br />
              - Use screed bars and a screed board to level the layer evenly
              without compacting it.
              <br />
            </p>
            <img
              src={halfwayPaverPathway}
              alt="Partially completed paver pathway with stones laid in a staggered pattern."
              title="Midway Progress of Paver Pathway Installation"
              className="img-responsive"
            />
            <p>
              <span>7. Final Inspection and Cleanup:</span>
              <br />
              - Lay the Pavers:
              <br />
              - Arrange Belgard pavers in your chosen pattern, leaving
              consistent spacing between each paver.
              <br />
              - Check Alignment:
              <br />- Use a straight edge or string line to ensure the pavers
              are level and aligned properly.
            </p>
            <img
              src={patternPaverPathway}
              alt="Pavers being installed in a herringbone pattern on a garden pathway."
              title="Installing Herringbone Pattern Pavers on Pathway"
              className="img-responsive"
            />

            <p>
              <span>8. Jointing Sand - Optional Use:</span>
              <br />
              The choice of whether to use jointing sand depends on the desired
              functionality and aesthetic of the walkway.
              <br />
              <span>- Option 1: Using Jointing Sand:</span>
              <br />
              - Spread and Fill:
              <br />
              - Pour jointing sand—polymeric or non-polymeric—over the surface
              of the pavers and sweep it into the joints.
              <br />
              <img
                src={paverEdgeAlignment}
                alt="Paver stones aligned with edge restraints during pathway installation."
                title="Aligning Pavers with Edge Restraints for a Clean Finish"
                className="img-responsive"
              />
              <br />
              - Compact:
              <br />
              - Use a plate compactor to settle the sand and stabilize the
              pavers.
              <br />
              - Activate (if Polymeric):
              <br />
              - Lightly mist the surface with water to activate the polymers,
              forming a water-resistant bond that resists erosion and weed
              growth.
              <br />
              <img
                src={finishedPaverPathway}
                alt="Nearly completed paver pathway with edging and final touches in progress."
                title="Nearly Completed Paver Pathway with Edging Installation"
                className="img-responsive"
              />
              <br />
              - Benefits:
              <br />
              - Jointing sand provides added stability, reduces the chance of
              weed growth, and prevents excessive water infiltration into the
              base.
              <br />
              <img
                src={paverPathwayComplete}
                alt="Completed paver pathway with edge restraints and sanded joints."
                title=" Finished Paver Pathway with Final Sanding and Edging"
                className="img-responsive"
              />
              <br />
              <span>Option 2 - No Jointing Sand:</span>
              <br />
              - Leave the Joints Open:
              <br />
              - If jointing sand is not used, the open joints will allow
              rainwater to flow directly into the base.
              <br />
              <img
                src={finishedPaverWalkway}
                alt="Newly finished paver walkway with precise edges and smooth surface."
                title="Fully Completed Paver Walkway Installation"
                className="img-responsive"
              />
              <br />
              - Impact:
              <br />
              - While this enhances the permeability of the walkway, it requires
              careful attention to ensure the base is compacted and stable to
              handle increased water flow.
              <br />
              <img
                src={paverCornerDetail}
                alt=" Close-up of neatly installed paver pathway corner by a building."
                title="Detailed Corner Installation of Paver Pathway"
                className="img-responsive"
              />
              <br />
              - Best for:
              <br />- Applications where rapid drainage is desired, such as
              areas prone to pooling or in permeable paving systems.
            </p>
            <img
              src={seamlessPaverTransition}
              alt="Smooth transition from paver pathway to building entrance with level alignment."
              title="Seamless Paver Pathway Transition to Building Entrance"
              className="img-responsive"
            />
            <p>
              <span>9. Final Inspection and Cleanup:</span>
              <br />
              - Inspect the Walkway:
              <br />
              - Confirm the pavers are level, joints are properly filled (if
              applicable), and the surface is stable.
              <br />
              - Clean the Surface:
              <br />
              - Sweep away any excess sand or debris to reveal a clean, finished
              walkway.
              <br />
              <img
                src={paverPathwayFinalTouch}
                alt="Final touches on a completed paver pathway with installed edging."
                title="Final Touches on Paver Pathway with Edging Installation"
                className="img-responsive"
              />
              <br />
              Why an Open-Graded Base is Essential in Greater Vancouver In
              regions like Richmond, North Vancouver, and Delta, heavy rainfall
              can pose significant challenges. An open-graded base using
              three-quarter inch clear crush and nine-mil bedding offers several
              advantages:
              <br />
              - Exceptional Drainage: Prevents water pooling and reduces frost
              heaving in colder months.
              <br />
              - Erosion Resistance: Maintains stability even during extended
              rainy periods.
              <br />
              - Long-Term Durability: Minimizes settling and ensures your
              walkway remains level and functional for years.
              <br />
            </p>
            <img
              src={completedModernPaverPathway}
              alt="Finished modern paver pathway leading around a building with clean, precise edging."
              title="Completed Modern Paver Pathway with Clean Edging"
              className="img-responsive"
            />

            <p>
              By following these steps and tailoring the installation to the
              unique conditions of your site, you can create a Belgard paver
              walkway that is both stunning and resilient, even in Greater
              Vancouver's rainy climate.
              <img
                src={elegantPaverWalkwayInstallation}
                alt="Newly installed elegant paver walkway with a smooth surface and defined edges."
                title="Elegant Paver Walkway Installation Completed"
                className="img-responsive"
              />{" "}
              <br />
              For professional walkway installation or expert advice in
              Vancouver, Burnaby, Surrey, or any other municipality in the
              Greater Vancouver area, contact BUR-HAN Garden & Lawn Care. Our
              experienced team ensures your project withstands the Pacific
              Northwest's heavy rains while enhancing your outdoor space.
            </p>
            <img
              src={finishedHerringBonePaver}
              alt="Finished herringbone pattern paver pathway with secure edge restraints."
              title="Finished Paver Pathway with Herringbone Design"
              className="img-responsive"
            />
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
