import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import pollinatorFriendlyFlowerGarden from "../../../assets/img/pollinator-friendly-flower-garden-vancouver.jpg";
import lavenderPlantsBlooming from "../../../assets/img/lavender-plants-blooming-vancouver.jpg";
import purpleConeflowerGarden from "../../../assets/img/purple-coneflower-garden-vancouver.jpg";

export default function Pollinator() {
  
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Creating a Pollinator and Native Plant Garden Blog";
    document.title = newTitle;

    const newDescription =
    "Enhance your garden's biodiversity with native plants and pollinator-friendly designs in British Columbia. Learn about plant selection, garden benefits, and maintenance tips from Bur-Han Garden and Lawn Care experts.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Pollinator garden Vancouver, Native plant garden BC, Pollinator-friendly landscaping Vancouver, Gardening for bees and butterflies, Eco-friendly garden design Vancouver, How to create a pollinator garden in Vancouver, Best native plants for pollinators in British Columbia, Pollinator garden design tips for small spaces in Vancouver, Sustainable landscaping with native plants in Vancouver, Wildflower garden ideas for pollinators in BC, Bee and butterfly-friendly plants for Lower Mainland gardens"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost pollinators">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Creating a Pollinator
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                and Native Plant Garden
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Pollinator and native plant gardens are increasingly popular, not
              only for their beauty but also for their ecological benefits.
              These gardens support local ecosystems by providing habitats and
              food sources for pollinators like bees, butterflies, and birds. In
              the Lower Mainland of Vancouver, British Columbia, you can create
              a thriving pollinator garden with a variety of native plants,
              whether you have a large yard or a small balcony.
            </p>
            <p>
              <span>Garden Size: Big or Small?</span>
              <br />
              One of the great aspects of pollinator gardens is their
              flexibility in size. You don't need a large garden to make a
              significant impact. Even small spaces, such as balcony planters or
              small patches in urban yards, can support pollinators if planted
              thoughtfully.
              <br />
              <span> Small Spaces:</span> Use containers, window boxes, or small
              garden beds to create mini pollinator habitats. Plants like
              lavender, sage, and thyme can thrive in small spaces and attract
              pollinators.
              <br />
              <span>Large Gardens:</span> Larger areas can incorporate a variety
              of plant types, including trees, shrubs, and ground covers.
              Consider designing your garden with diverse layers to provide
              various habitats and food sources throughout the year.
            </p>
            <img
              src={purpleConeflowerGarden}
              alt="Bright pink coneflower in a beautifully maintained Vancouver garden"
              title="Stunning Pink Coneflower in Vancouver Garden "
              className="imgblog"
            />
            <p>
              <span>Benefits of Pollinator Gardens:</span>
              <br />
              Pollinator gardens offer numerous benefits, both to the
              environment and to humans:
              <br />
              <span> Support Biodiversity:</span> By planting native species,
              you provide essential resources for local pollinators and other
              wildlife, helping to maintain and increase biodiversity.
              <br />
              <span>Improve Pollination:</span> Gardens with a variety of
              pollinator-friendly plants can enhance the pollination of nearby
              crops and gardens, leading to better yields and more robust plant
              health.
              <br />
              <span>Educational Opportunities:</span> Pollinator gardens can
              serve as living classrooms for children and adults alike, offering
              opportunities to learn about local ecosystems, plant biology, and
              the importance of conservation.
              <br />
              <span>Aesthetic Value:</span> Native plants often have unique and
              striking appearances, adding beauty and interest to your garden
              throughout the seasons.
              <br />
              <span>Environmental Benefits:</span> These gardens can help reduce
              soil erosion, improve water retention, and decrease the need for
              chemical fertilizers and pesticides.
            </p>
            <p>
              <span>Recommended Native Plants for Pollinator Gardens:</span>
              <br />
              Here are some native plants suitable for creating a pollinator
              garden in the Lower Mainland of Vancouver, British Columbia:
              <br />
              <span>Evergreen Huckleberry (Vaccinium Ovatum):</span> A large
              shrub with edible berry production. Flowers attract hummingbirds,
              butterflies and other pollinators.
              <br />
              <span>Oregon Grape (Mahonia Nervosa): </span>An evergreen shrub
              with yellow flowers that attract bees and blue berries that feed
              birds.
              <br />
              <span>Salal (Gaultheria shallon):</span> A versatile ground cover
              with white or pink flowers that attract bees, followed by dark
              blue berries for birds.
              <br />
              <span>Artemisia Tridentata (Sagebrush):</span> A common woody and
              herbaceous species of plants, provides shelter for small wildlife
              and is great for pollinators.
              <br />
              <span>Pacific Ninebark (Physocarpus Capitatus):</span> Recognized
              by its brown shedding bark, and has flowers most often pollinated
              by native bees, but may also be visited by butterflies.
              <br />
              <span>Snowberry (Symphoricarpus Albus):</span> A sparsely branched
              shrub, bear small leaves and small inconspicuous flower clusters
              followed by large, snow white berry-like fruit that attracts
              butterflies and moths.
              <br />
              <span>Pacific Bleeding Heart (Dicentra Formosa): </span>Clusters
              of pink, heart shaped flowers (flushed with lavender) are attached
              to the leafless stems of this perennial which are a great
              attractant for hummingbirds.
              <br />
              <span>Common Cinquefoil (Potentilla Fruticosa):</span> A bushy
              deciduous shrub boasting masses of large, rich yellow flowers that
              attract butterflies and is one of the easiest plants to care for.
              <br />
            </p>
            <p>
              <span>
                Sowing Wildflowers for Pollinator Gardens:
                <br />
              </span>
              Wildflowers can be a vibrant and effective addition to pollinator
              gardens. They are typically easy to grow and provide a rich source
              of nectar and pollen. Here are some steps and recommendations for
              sowing wildflowers in your garden:
              <br />
            </p>
            <p>
              <span>Choosing Wildflower Seeds:</span> Select a mix of wildflower
              seeds that are native to the Lower Mainland of Vancouver or
              suitable for the local climate. <br /> Good choices include:
              <br />
              <span>California Poppy (Eschscholzia californica):</span> Bright
              orange flowers that attract bees and butterflies.
              <br />
              <span>Common Yarrow (Achillea millefolium):</span> White to pink
              flowers that are excellent for pollinators.
              <br />
              <span>Blanketflower (Gaillardia aristata): </span>Red and yellow
              flowers that provide nectar for bees and butterflies.
              <br />
              <span>Black-eyed Susan (Rudbeckia hirta):</span> Yellow flowers
              with dark centers that attract a variety of pollinators.
              <br />
              <img
                src={pollinatorFriendlyFlowerGarden}
                alt=" Colorful wildflower garden designed to attract pollinators in Vancouver."
                title="Beautiful Wildflower Gardens for Pollinators in Vancouver "
                className="imgblog"
              />
              <span>Preparing the Soil: </span>Clear the area of weeds and
              loosen the soil to create a hospitable environment for the seeds.
              Wildflowers generally prefer well-drained soil. <br />
              <span>Sowing the Seeds:</span> Follow the instructions on the seed
              packet for the best results. Typically, you will scatter the seeds
              over the prepared soil and lightly rake them in.
              <br />
              <span>Watering and Maintenance: </span>Water the seeds gently and
              keep the soil moist until the seedlings are established. Once
              established, wildflowers are usually low-maintenance and require
              minimal care.
              <br />
            </p>
            <p>
              <span>Beneficial Non-Native Plants for Pollinator Gardens:</span>
              <br />
              In addition to native plants, certain non-native species can also
              be beneficial for pollinator gardens in Vancouver, BC. These
              plants are well-adapted to the local climate and provide valuable
              resources for pollinators:
              <br />
              <span>Lavender (Lavandula spp.):</span> A fragrant herb with
              purple flowers that attract bees and butterflies.
              <br />
              <span>Rosemary (Rosmarinus officinalis): </span>Another fragrant
              herb with blue flowers loved by bees.
              <br />
              <span>Echinacea (Echinacea purpurea):</span> Also known as
              coneflower, this plant has large, showy flowers that attract a
              variety of pollinators.
              <br />
              <span>Buddleja (Buddleja davidii): </span>Known as butterfly bush,
              it produces large clusters of flowers that are highly attractive
              to butterflies and bees.
              <br />
              <span>California Lilac (Ceanothus): </span>Known as a vibrant
              flowering shrub native to North America (specifically California)
              and found across the west growing wild. It produces amazingly
              fragrant purple blooms from late spring into early summer.
              <br />
              <span>Rose of Sharon (Hibiscus Syriacus):</span> An exotic beauty,
              Rose of Sharon is a vigorous vase shaped, deciduous shrub with
              large showy flowers that attract butterflies and hummingbirds.
              <br />
              <span>Mexican Orange Blossom (Choisya Ternata):</span> A fragrant,
              rounded evergreen with pure white flowers. Both the flowers and
              leaves smell of orange blossoms and attract both bees and
              butterflies.
              <br />
              <span>CandyTuft (Iberis Sempervirens):</span> A low sprawling,
              woody-based perennial with abundant clusters of 4-petaled white
              flowers, enhanced by yellow dots attracts a variety of
              pollinators. <br />
            </p>
            <p>
              <span>Designing Your Pollinator Garden:</span>
              <br />
              When designing your pollinator garden, consider the following
              tips:
              <br />
              <span>Diversity: </span>Plant a variety of species to provide
              continuous bloom throughout the growing season, ensuring a steady
              supply of nectar and pollen.
              <br />
              <span>Shelter:</span> Include plants of different heights and
              structures to offer shelter and nesting sites for pollinators.
              <br />
              <span>Water Source:</span> Provide a shallow water source, such as
              a birdbath with stones for insects to land on.
              <br />
              <span>Avoid Pesticides:</span> Use organic gardening practices to
              protect pollinators from harmful chemicals.
              <br />
            </p>
            <img
              src={lavenderPlantsBlooming}
              alt="Blooming lavender plants in a vibrant Vancouver garden"
              title="Lavender Plants in Full Bloom | Vancouver Garden Highlights"
              className="imgblog"
            />
            <p>
              <span>Conclusion:</span>
              <br />
              Creating a pollinator and native plant garden is a rewarding
              endeavor that benefits both the environment and your personal
              enjoyment. Whether you have a large yard or a small urban space,
              you can contribute to the health and diversity of local ecosystems
              by planting native species and beneficial non-native plants that
              support pollinators. Start small, plan thoughtfully, and enjoy the
              beauty and life that these gardens bring to your surroundings."Start small, plan thoughtfully, and enjoy the beauty and life that these gardens bring to your surroundings. Check out our <a href="/blog/garden-maintenance-guide" style={{color: "var(--yellow-color)"}}>garden maintenance guide</a> for additional tips. For
              more detailed guidance and plant recommendations, consider
              visiting local nurseries or consulting with a local horticultural
              society. For expert assistance with your pollinator garden,
              contact BUR-HAN Garden & Lawn Care - your trusted landscaping
              professionals in Vancouver, BC. We can help you with{" "}
              <a
                href="/services/design-and-modelling"
                style={{ color: "var(--yellow-color)"}}
              >
                design
              </a>{" "}
              as well as{" "}
              <a
                href="/services/softscape-installs-maintenance"
                style={{ color: "var(--yellow-color)", }}
              >
                plant selection and installation
              </a>
              . Contact us today for a free quote!
            </p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping team
                  bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
